:root {
    --black: #000;
    --black-10: rgba(0, 0, 0, 0.1);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-80: rgba(0, 0, 0, 0.8);
    --black-90: rgba(0, 0, 0, 0.9);

    --gray-100: #f8f8f8;
    --gray-200: #d8d8d8;
    --gray-400: #a9a9a9;
    --gray-600: #4a4a4a;
    --gray-700: #282828;

    --white: #fff;
    --white-10: rgba(255, 255, 255, 0.1);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-90: rgba(255, 255, 255, 0.9);

    --aqua: #00b0b8;
    --aqua-300: #6ad0d4;
    --blue-twitter: #3196d4;
    --purple: #2c1341;
    --purple-50: rgba(44, 19, 65, 0.5);
    // --purple-100: #f6f0ff;
    --purple-300: #594376;
    --purple-bright: #c86dd7;
    --yellow: #fbbf11;

    --grad-aqua-purple-diag: linear-gradient(109deg, var(--aqua-300) 0%, var(--purple-300) 100%);
    --grad-purple-diag: linear-gradient(109deg, var(--black) 0%, #6C377E 44%, var(--purple-bright) 100%);
    --grad-purple-light-diag: linear-gradient(109deg, #3F1D53 0%, #A056B1 100%);
    --grad-purple-aqua-diag: linear-gradient(105deg, var(--purple-300) 35%, var(--aqua) 100%);
    --grad-purple-aqua-down: linear-gradient(180deg, var(--purple-300) 15%, var(--aqua) 120%);
    --grad-purple-aqua-up: linear-gradient(0deg, var(--purple-300) 15%, var(--aqua) 120%);

    --font-sans: 'titillium-web', sans-serif;
    --font-abolition: 'abolition', sans-serif;
    --fontweight-normal: 400;
    --fontweight-semibold: 600;
    --fontweight-bold: 700;

    --link-color: #036dc6;
    --link-color-visited: #4b11af;

    --red: #a50034;
    --orange: #fb6510;
    --green: #28b600;
}

// Breakpoints
$bp-xs: 30em;  // 480px
$bp-sm: 40em;  // 640px
$bp-md: 50em;  // 800px
$bp-lg: 60em;  // 960px
$bp-xl: 80em;  // 1280px
$bp-xxl: 90em; // 1440px
