/*======================================
    Buttons
======================================*/

%btn {
    --btn-bg-color: transparent;
    --btn-bg-color-hover: var(--aqua-300);
    --btn-border-color: var(--black);
    --btn-border-color-hover: var(--black);
    --btn-text-color: var(--black);
    --btn-text-color-hover: var(--black);

    background-color: var(--btn-bg-color);
    border: 1px solid var(--btn-border-color);
    border-radius: 0;
    color: var(--btn-text-color);
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: var(--fontweight-semibold);
    line-height: 1;
    margin: 0 1rem 0.5rem 0;
    padding: 0.5em 3em 0.5em 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;
    white-space: nowrap;

    &:hover,
    &:focus {
        background-color: var(--btn-bg-color-hover);
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
    }

    &::after {
        aspect-ratio: 1 / 1;
        background-color: var(--btn-border-color);
        background-image: url('../images/ui/arrow-diag-white.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: .75rem;
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color 250ms ease;
        width: 2.25em;
    }
}

%btn-white {
    --btn-border-color: var(--white);
    --btn-text-color: var(--white);
    --btn-bg-color-hover: var(--purple-300);
    --btn-border-color-hover: var(--white);
    --btn-text-color-hover: var(--white);

    &::after {
        background-image: url('../images/ui/arrow-diag-black.svg');
    }
}

.btn {
    @extend %btn;

    &-yellow {
        --btn-border-color: var(--yellow);
        --btn-text-color: var(--white);
        --btn-bg-color: var(--black-50);
        --btn-bg-color-hover: var(--black);
        --btn-border-color-hover: var(--white);
        --btn-text-color-hover: var(--white);

        &:hover,
        &:focus {
            &::after {
                background-color: var(--white);
            }
        }

        &::after {
            background-color: var(--yellow);
            background-image: url('../images/ui/arrow-diag-black.svg');
        }
    }

    &-white {
        @extend %btn-white;
    }

    &-search {
        background-color: var(--btn-border-color);
        background-image: url('../images/ui/search-white.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1rem;
        border: 0;
        min-width: 3rem;
        overflow: hidden;
        text-indent: -9999px;

        &::after {
            display: none;
        }
    }
    
    &-small { 
        font-size: rem(15px);
    }

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &[disable],
    &.slick-disabled {
        --btn-bg-color: var(--gray-400);
        --btn-border-color: var(--gray-600);
        --btn-text-color: var(--gray-600);
        --btn-bg-color-hover: var(--gray-400);
        --btn-border-color-hover: var(--gray-400);
        --btn-text-color-hover: var(--gray-600);

        cursor: default;
        pointer-events: none;
        touch-action: none;
    }
}
